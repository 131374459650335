import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link"
import { Heading } from "rebass"
import Paragraph from "../components/paragraph"

const StyledList = styled.ul`
  margin: 2em;
`

const StyledListItem = styled.li``

const pages = [
  { url: "/", label: "Claude Tranchant the author" },
  { url: "/boots-to-bliss", label: "Boots to Bliss the book" },
  { url: "/boots-to-freedom", label: "Boots to Freedom the book" },
  {
    url: "/australian-book-stores",
    label: "Buy at these Australian book stores",
  },
]

const NotFoundPage = () => (
  <Layout withHeader={false}>
    <SEO title="404: Page Not found" openGraph={{ namespace: "", meta: {} }} />
    <Heading fontSize={6} mb={3}>
      Sorry, this page does not exist
    </Heading>
    <Paragraph>You might find what you are looking for at</Paragraph>
    <StyledList>
      {pages.map(({ url, label }, key) => (
        <StyledListItem key={`page-${key}`}>
          <Link to={url} title={label}>
            {label}
          </Link>
        </StyledListItem>
      ))}
    </StyledList>
  </Layout>
)

export default NotFoundPage
